import React from 'react';
import { Link } from 'gatsby';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import posterImg from '../imgs/tfk-cover.jpg';
 
import { LandingContainer } from '../styles/index.css';
import { GlobalStyle } from '../styles/common.css';

import MainNav from '../components/mainNav';

import Footer from '../components/footer';

function IndexPage({ data }) {
  return (
    <>
      <GlobalStyle />
      <LandingContainer>
        <main className="top" role="main">
          <Img className="cover" fluid={data.datoCmsIndex.cover.fluid} />
          <MainNav />
          <div className="main-info drop-down">
            <Img
              className="logo fade-in"
              fluid={data.datoCmsIndex.logo.fluid}
              alt="Logo"
            />
            <h2>{data.datoCmsIndex.launch}</h2>
            <img
              className="abc fade-in"
              src={require('../imgs/cw-logo.png')}
              alt="CW logo"
            />
          </div>
        </main>
        <section className="submit-details">
          <div
            dangerouslySetInnerHTML={{ 
              __html:
                data.datoCmsIndex.uploadDescriptionNode.childMarkdownRemark.html
            }}
          />
          <Link className="btn fade-in submit-video" to="/upload">
            Submit a Video
          </Link>
          <p className="warning">{data.datoCmsIndex.uploadWarning}</p>
        </section>

        <section className="video">
          <video width="100%" controls poster={posterImg}>
            <source id="mp4" src="https://d20eudbgldb3n3.cloudfront.net/afvweb/AFV-c1e220f9-fb40-4600-aa01-db47e5a19dc9.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </section>

        <section className="details">
          <div>
            <h1>{data.datoCmsIndex.title}</h1>
            <div
              className="noset"
              dangerouslySetInnerHTML={{
                __html: data.datoCmsIndex.bodyNode.childMarkdownRemark.html
              }}
            />
            <p>
              <span className="announcement">
                {data.datoCmsIndex.announcement}
              </span>
            </p>
          </div>
          <img
            className="bob fade-in"
            src={require('../imgs/TacarraWilliams_optionB-OPTIMIZED.png')}
            alt="Bob Saget"
          />
        </section>

        <Footer />
      </LandingContainer>
    </>
  );
}

export default IndexPage;

export const query = graphql`
  query IndexQuery {
    datoCmsIndex {
      cover {
        url
        fluid(maxWidth: 3000, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsSizes
        }
      }
      logo {
        fluid(maxWidth: 350, imgixParams: { fm: "png", auto: "compress" }) {
          src
        }
      }
      launch
      title
      uploadDescriptionNode {
        childMarkdownRemark {
          html
        }
      }
      uploadWarning
      bodyNode {
        childMarkdownRemark {
          html
        }
      }
      announcement
    }
  }
`;
